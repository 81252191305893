<template>
    <div :style="{ height: screeHeight + 'px' }" style="background-color: rgb(239 239 239);">
        <div style="padding-top: 20px;">
            <div v-for="item in lists" :key="item.id" style="border-bottom: 1px solid #f2f3f5;">
                <van-cell>
                    <template #title>
                        <strong style="font-size: 18px;">{{item.playerName}}</strong>
                        <van-icon name="edit" @click="toEdit(item.id)" style="font-size: 19px;font-weight: bold;float: right;"/>
                        <van-icon name="delete-o" @click="toDel(item.id)" style="font-size: 19px;font-weight: bold;float: right;padding: 0px 20px;"/>
                        
                    </template>
                </van-cell>
                <van-cell title="手机号" :value="item.mobile" size="large" />
                <van-cell title="身份证号" :value="item.idcardNumber" size="large" />
            </div>
        </div>
        <van-button round type="danger" @click="$router.push('/personal/addChuyouren')" style="" class="add-chuyouren">添加常用出游人</van-button>
    </div>
</template>
<script>
import { Toast,Dialog  } from 'vant';
export default {
  name: 'info',
  data(){
        return {
            screenWidth: document.documentElement.clientWidth,     // 屏幕宽
            screeHeight: document.documentElement.clientHeight,    // 屏幕高
            lists:[],
            active:0
        }
    },
    created(){
        this.getList()
    },
    mounted(){
    },
    methods:{
        toDel(id){
            Dialog.confirm({
                title: "确定删除该出游人吗",
            }).then(() => {
            this.$http({
              method: "post",
              url: `${this.apis.playerDel}?id=${id}`,
            }).then((res) => {
                if (res.code == 200) {
                  Toast({
                      message: '删除成功'
                    });
                    this.getList()
                }
            })
          })
        },
        getList(){
            this.$http({
                method: "post",
                url: `${this.apis.playerList}`,
            }).then((res) => {
                if (res.code == 200) {
                    this.lists = res.data
                }
            });
        },
        toEdit(id){
            this.$router.push({path:'/personal/addChuyouren',query:{id:id}})
        }
    }

}
</script>
<style scoped>
.active{
    width: 70px;
    background-color: #fd1a34;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 3px;
}
.nactive{
    width: 70px;
    padding: 3px;
    text-align: center;
    border-radius: 15px;
}
.add-chuyouren{
    width: 97%;
    margin-left: 1.5%;
    position: fixed;
    bottom: 6px;
}
</style>